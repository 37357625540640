<template>

<div>

  <reports :reportSection="3" />

</div>

</template>

<script>
import Reports from '@/components/Reports';

export default {
  name: 'ReportsSettings',
  components: {
    Reports,
  },
};
</script>

<style scoped>

</style>
